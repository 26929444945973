import Vue from 'vue'
import ProductSelector from './App.vue'
import Filters from './filters'
import 'document-register-element/build/document-register-element'
import './assets/css/styles.css'
import VueCustomElement from 'vue-custom-element'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faTimesCircle, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faTimes)
library.add(faTimesCircle)
library.add(faCaretDown)

Vue.config.productionTip = false

Vue.use(VueCustomElement)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)

// Add Filters
Object.keys(Filters).forEach(key => {
  Vue.filter(key, Filters[key])
})

Vue.customElement('product-selector', ProductSelector, {})
