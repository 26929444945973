// Imports
import AxiosConfig from '@/config/AxiosConfig'

// Get Manufacturers
export const getManufacturers = async () => {
  const url = '/get-manufacturers'

  try {
    const data = await AxiosConfig.get(url)

    if (!data.data) return []

    return data?.data?.manufacturers
  } catch (e) {
    console.log(e)
  }
}

// Get Models
export const getModels = async (manufacturer) => {
  const url = '/get-models'

  try {
    const data = await AxiosConfig.get(url, {
      params: {
        manufacturer
      }
    })

    if (!data.data) return []

    return data?.data?.models
  } catch (e) {
    console.log(e)
  }
}

// Get Cables Results
export const getCablesResults = async (manufacturer, model, port, companyDomain, jerkstopper, perPage, page, search) => {
  const url = '/make-cables-query'

  try {
    const data = await AxiosConfig.get(url, {
      params: {
        manufacturer,
        model,
        port,
        company_domain: companyDomain,
        jerkstopper: jerkstopper,
        per_page: perPage,
        page,
        search
      }
    })

    if (!data.data) return []

    return data?.data
  } catch (e) {
    console.log(e)
  }
}
