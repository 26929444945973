<template>
  <!-- Product Category -->
  <div class="product-category" :class=category.customClass>
    <!-- Title -->
    <div class="product-category--title">
      {{ category.title }}
    </div>

    <!-- Description -->
    <div class="product-category--description">
      {{ category.description }}
    </div>

    <!-- Product Cards List -->
    <div class="product-category--products">
      <div
        v-for="(product, index) in category.products"
        :key="index"
        class="product-category--products--item"
      >
        <ProductItemCard :product="product" />
      </div>
    </div>
  </div>
</template>

<script>
import ProductItemCard from '@/components/product-selector/ProductItemCard'

export default {
  name: 'ProductCategory',
  components: { ProductItemCard },
  props: {
    category: {
      type: Object,
      default: () => {
        return {
          products: []
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
.product-category
  border-bottom: 2px solid #E6E6E6
  padding: 50px 0

  &.pc-max-width
    max-width: 1100px

  &--title
    font-size: 28px
    font-weight: 500
    padding: 0 15px

  &--description
    font-size: 14px
    font-weight: 400
    margin: 15px 0 30px 0

  &--products
    display: flex
    flex-wrap: wrap

    &--item
      padding-bottom: 30px
      padding-right: 15px
      padding-left: 15px
      margin: 0
      width: 332px

@media screen and (max-width: 800px)
  .product-category
    &--products
      &--item
        margin: 0 auto

@media screen and (max-width: 739px)
  .product-category
    &--title
      text-align: center
    &--products
      flex-direction: column
      &--item
        width: auto
</style>
