<template>
  <!-- Base Modal -->
  <div class="base-modal">
    <div class="base-modal--card">
      <!-- Modal Toolbar -->
      <div class="base-modal--toolbar">
        <slot name="toolbar" />

        <FontAwesomeIcon
          class="base-modal--toolbar--close"
          :icon="['fas', 'times-circle']"
          @click="$emit('close')"
        />
      </div>

      <!-- Modal Content -->
      <div class="base-modal--content">
        <slot />
      </div>

      <!-- Modal Actions -->
      <div class="base-modal--actions">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal'
}
</script>

<style scoped lang="sass">
.base-modal
  align-items: center
  background-color: rgba(0, 0, 0, 0.29)
  display: flex
  height: 100vh
  justify-content: center
  left: 0
  position: fixed
  top: 0
  width: 100vw
  z-index: 5000

  &--card
    align-items: center
    background-color: white
    border-radius: 5px
    display: flex
    min-height: 240px
    height: auto
    min-width: 400px
    position: relative

  &--toolbar
    display: flex
    position: absolute
    justify-content: space-between
    width: 95%
    padding: 20px
    font-size: 22px
    top: 0

    &--close
      color: grey
      cursor: pointer
      font-size: 22px !important
      margin-top: -5px

  &--content
    padding: 0 20px
    width: 100%

  &--actions
    position: absolute
    bottom: 0
    width: 100%
    padding: 20px
</style>
