<template>
  <div
    class="base-loading-spinner"
    :style="getSpinnerStyle"
  />
</template>

<script>
export default {
  name: 'BaseLoadingSpinner',
  props: {
    size: {
      type: [String, Number],
      default: '25'
    }
  },
  computed: {
    getSpinnerStyle () {
      return {
        width: this.size + 'px',
        height: this.size + 'px'
      }
    }
  }
}
</script>

<style scoped lang="sass">
.base-loading-spinner
  border: 2px white solid
  border-top: 4px black solid
  animation: spin 0.6s linear infinite
  margin: 0 auto
  border-radius: 50%

@keyframes spin
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
</style>
