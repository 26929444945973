<template>
  <div class="base-header">
    <img src="@/assets/logo.png">
    <div class="base-header-title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'BaseHeader',
  props: {
    title: String
  }
}
</script>

<style lang="sass" scoped>
  .base-header
    padding: 20px 25px
    margin-bottom: 30px
    display: flex
    align-items: center
    background: #000000

    img
      width: 35px
      margin-right: 15px

    .base-header-title
      font-weight: normal
      font-size: 28px
      margin: 0
      color: #ffffff
</style>
